import React, { useState, useEffect } from 'react'
import { Box, useToast } from '@chakra-ui/react'
import { FetchCombis, FetchRunning, GetLongDate, parseDate } from '../Queries/Queries'
import TableRunning from './TableRunning'
import { Queryparams } from '../Constants/Constants';
import { genPDF_combi } from './PdfMakeTable';

var ws = null;
var gm = ""

const RunningForm = ({ selGame, token, wstoken, setnewEntry }) => {
    const [running, setrunning] = useState(null)
    const toast = useToast()

    useEffect(() => {
        GetRunning()
    }, [selGame.gcode])

    useEffect(() => {
        ws = new WebSocket("wss://websocks.bistaya.com/ws");
        try {
            gm = localStorage.getItem('game');
        } catch (err) {
        }
        return () => {
            ws.close()
        }
    }, [])


    if (ws !== null) {
        ws.onopen = () => {
            try {
                ws.send(JSON.stringify({
                    "token": wstoken
                }));
            } catch (err) {

            }
        };

        ws.onmessage = function (event) {
            const json = JSON.parse(event.data);
            if (json.channel) {
                if (json.channel === Queryparams.areapref + "-" + gm) {
                    setrunning(json.pub.data)
                } else if (json.channel === Queryparams.areapref + "-bets-" + gm) {
                    setnewEntry(json.pub.data)
                }

            }
        }
    }


    const onClickDownload = () => {
        selGame.gcode && FetchCombis(token, selGame.gcode).then(res => {
            if (res.err) {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: res.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            } else {
                setrunning(res.data)
                genPDF_combi(res.data, Queryparams.areanamepdf + " " + gm.toUpperCase() + " COMBINATION -AMOUNT REPORT", parseDate(new Date), GetLongDate(), gm)
            }
        }).catch()

    }


    const GetRunning = () => {
        setrunning(null)
        selGame.gcode && FetchRunning(token, selGame.gcode).then(res => {
            if (res.err) {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: res.data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            } else {
                setrunning(res.data)
            }
        }).catch()
    }

    return (
        <Box>
            <TableRunning
                selGame={selGame}
                combis={running}
                onClickDownload={onClickDownload}
            />
        </Box>
    )
}

export default RunningForm